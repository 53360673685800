import React, { useEffect, useState } from 'react';
import { useClassNames, useToastAction } from '@metaforcelabs/metaforce-core';
import { getUsageStatistics } from '../../../api/dashboard';
import { Link } from 'react-router-dom';
import { Building2, Check, LogIn, Mail, MessageSquareMore, SquarePen, Timer, TimerReset, Users } from 'lucide-react';

const PathNameMappToRoute = {
  ApprovalsPending: '/admin/approvals?status=pending',
  ApprovalsActive: '/admin/approvals?status=active'
};

const LucideIconFactory = ({ icon }) => {
  switch (icon) {
    case "timer":
      return <Timer className='text-2xl text-white' />
    case "user-group":
      return <Users className='text-2xl text-white' />
    case "users":
      return <Building2 className='text-2xl text-white' />
    case "arrow-right-to-bracket":
      return <LogIn className='text-2xl text-white' />
    case "message":
      return <MessageSquareMore className='text-2xl text-white' />
    case "envelope":
      return <Mail className='text-2xl text-white' />
    case "check":
      return <Check className='text-2xl text-white' />
    case "pen-to-square":
      return <SquarePen className='text-2xl text-white' />
    default:
      return <></>

  }
};

const Dashboard = () => {
  const [statsUsage, setStatsUsage] = useState([]);
  const [statsUsers, setStatsUsers] = useState([]);

  const usageStatisticsAction = useToastAction();

  const { classNames } = useClassNames();

  const onInit = () => {
    usageStatisticsAction.execute(async () => {
      const result = await getUsageStatistics();

      const listForIcons = [...result.usageStatistics, ...result.usersStatistics];
      const iconNameList = listForIcons.map((r) => {
        const splitedName = r.icon.split('-');
        const nameToJoin = ['fa'];

        for (let i = 0; i < splitedName.length; i++) {
          const splitedNameItem = splitedName[i];

          nameToJoin.push(splitedNameItem.charAt(0).toUpperCase() + splitedNameItem.slice(1));
        }

        return nameToJoin.join('');
      });

      // const iconsList = Object.keys(Icons)
      //   .filter((icon) => {
      //     return iconNameList.some((iconName) => {
      //       return icon === iconName;
      //     });
      //   })
      //   .map((icon) => Icons[icon]);

      // library.add(...iconsList);

      setStatsUsage(result.usageStatistics);
      setStatsUsers(result.usersStatistics);
    }, '');
  };

  useEffect(() => {
    onInit();
  }, []);

  return (
    <div className="w-full h-full">
      <h1 className="text-3xl font-bold">PingDox Dashboard</h1>
      <h3 className="mt-4 text-gray-600">
        Below you will find the statistics of your PingDox ecosystem usage
      </h3>

      <h2 className="text-md font-bold mt-10 text-gray-600">Current number of customers</h2>
      <dl className="mt-6 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
        {statsUsers.map((item, index) =>
          item.pathName ? (
            <Link
              to={PathNameMappToRoute[item.pathName]}
              key={index}
              className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-5 shadow sm:px-6 sm:pt-6"
            >
              <div className="flex">
                <div
                  className={classNames(
                    'flex justify-center items-center rounded-md p-3 w-14 h-14 ',
                    item.bgColor
                  )}
                >
                  <LucideIconFactory icon={item.icon} />
                  {/* <FontAwesomeIcon icon={['fal', item.icon]} className="text-2xl text-white" /> */}
                </div>

                <div className="flex flex-col ml-6 truncate">
                  <p className="text-sm font-medium text-gray-500">{item.name}</p>
                  <p className="text-2xl font-semibold text-gray-900">{item.value}</p>
                </div>
              </div>
            </Link>
          ) : (
            <div
              key={index}
              className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-5 shadow sm:px-6 sm:pt-6"
            >
              <div className="flex">
                <div
                  className={classNames(
                    'flex justify-center items-center rounded-md p-3 w-14 h-14 ',
                    item.bgColor
                  )}
                >
                  <LucideIconFactory icon={item.icon} />
                  {/* <FontAwesomeIcon icon={['fal', item.icon]} className="text-2xl text-white" /> */}
                </div>

                <div className="flex flex-col ml-6 truncate">
                  <p className="text-sm font-medium text-gray-500">{item.name}</p>
                  <p className="text-2xl font-semibold text-gray-900">{item.value}</p>
                </div>
              </div>
            </div>
          )
        )}
      </dl>

      <h2 className="text-md font-bold mt-10 text-gray-600">Statistics of last 30 days usage</h2>
      <dl className="mt-6 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
        {statsUsage.map((item, index) => (
          <div
            key={index}
            className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-5 shadow sm:px-6 sm:pt-6"
          >
            <div className="flex">
              <div
                className={classNames(
                  'flex justify-center items-center rounded-md p-3 w-14 h-14 ',
                  item.bgColor
                )}
              >
                <LucideIconFactory icon={item.icon} />
                {/* <FontAwesomeIcon icon={['fal', item.icon]} className="text-2xl text-white" /> */}
              </div>

              <div className="flex flex-col ml-6 truncate">
                <p className="text-sm font-medium text-gray-500">{item.name}</p>
                <p className="text-2xl font-semibold text-gray-900">{item.value}</p>
              </div>
            </div>
          </div>
        ))}
      </dl>
    </div>
  );
};

export default Dashboard;
