import React, { useEffect, useState } from 'react'
import { getCustomersApi } from '../../../api/license';
import CustomerList from '../License/components/CustomerList';
import { ButtonV2, CoreButtonTypes, useClassNames, useModalHelper, useToastAction } from '@metaforcelabs/metaforce-core';
import { deleteCustomerData, getCustomerStatistics } from '../../../api/statistics';
import DeleteCustomerDataWarningModal from './deleteCustomerDataWarningModal';
import { Loader } from '../../../components';
import { divide } from 'lodash';

export default function Statistics({ }) {
    const [customerList, setCustomerList] = useState([])
    const [customerStatistics, setCustomerStatistics] = useState();
    const [selectedCustomer, setSelectedCustomer] = useState()
    const loadAction = useToastAction();
    const deleteAction = useToastAction();
    const { classNames } = useClassNames();
    const deleteModalHelper = useModalHelper();

    const load = async () => {
        await loadAction.executeAsync(async () => {
            const customers = await getCustomersApi();
            setCustomerList(customers);
            console.log('customers', customers)
        }, 'Failed to load customers')

    }

    useEffect(() => {
        load();
    }, [])

    const loadCustomerStatistics = async (customer) => {
        setSelectedCustomer(customer);
        const customerStats = await getCustomerStatistics(customer.customerId);
        console.log(customerStats);
        setCustomerStatistics(customerStats);
    }

    const handleCustomerSelected = (customer) => {
        loadAction.executeAsync(async () => {
            setSelectedCustomer(null);
            setCustomerStatistics(null);
            await loadCustomerStatistics(customer);
        }, 'Failed to load customer statistics');
    }

    const handleDeleteCustomerData = async () => {
        await deleteAction.executeAsync(async () => {
            console.log('Deleting customer data', selectedCustomer.customerId);
            await deleteCustomerData(selectedCustomer.customerId);
            await loadCustomerStatistics(selectedCustomer);
            deleteModalHelper.close();
            console.log('Customer data deleted');
        }, 'Failed to delete customer data');
    }

    const handleStartDeleteCustomerData = async () => {
        deleteModalHelper.open();
    }

    return (
        <div>
            <div className="flex flex-col pb-5 mb-10 border-b border-gray-200">
                <h1 className="text-3xl font-bold">Statistics</h1>
                <p className="mt-2 max-w-4xl text-sm text-gray-500">Manage statistics for customers</p>
            </div>
            <div className="mx-auto lg:grid  lg:grid-cols-12 lg:gap-8">
                <div className="mb-4 lg:mb-0 lg:col-span-3 lg:block xl:col-span-3 py-5 bg-white shadow shadow-sm rounded-lg">
                    {customerList.length > 0 && (
                        <CustomerList customers={customerList} onSelectCustomer={(c) => handleCustomerSelected(c)} />
                    )}
                </div>

                {selectedCustomer && (
                    <div className='relative lg:col-span-9 xl:col-span-9 p-5 bg-white shadow shadow-sm rounded-lg overflow-hidden space-y-4'>
                        <div className="border-b border-gray-200 pb-5 sm:pb-0">
                            <div className="mb-4">
                                <h3 className="text-lg font-medium leading-6 text-gray-900">{selectedCustomer?.name}</h3>

                                <dl className="mt-3 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-5">
                                    <div className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-gray-500">Org</dt>
                                        <dd className="mt-1 text-sm text-gray-900">{selectedCustomer?.orgNumber}</dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                        {
                            customerStatistics ? (<div>
                                <div className='border-b border-gray-200 pb-4'>
                                    <div className="text-sm font-medium text-gray-500">Statistics Licence Status</div>
                                    <div>
                                        <span className={classNames("font-semibold", customerStatistics.hasLicence ? "text-green-500" : "text-red-500")}>{customerStatistics.hasLicence ? 'Active' : 'Inactive'}</span>
                                    </div>
                                </div>
                                <div className='grid grid-cols-12 gap-4 border-b border-gray-200 pb-4'>
                                    <div className='col-span-6 lg:col-span-3 xl:col-span-2'>
                                        <div className="text-sm font-medium text-gray-500">Record Count</div>
                                        <div>
                                            <span className="font-semibold">{customerStatistics.databaseStats?.recordCount}</span>
                                        </div>
                                    </div>
                                    <div className='col-span-6 lg:col-span-3 xl:col-span-2'>
                                        <div className="text-sm font-medium text-gray-500">Storage Size</div>
                                        <div>
                                            <span className="font-semibold">{customerStatistics.databaseStats?.storageSizeInUnitBytes}</span>
                                        </div>
                                    </div>
                                    <div className='col-span-6 lg:col-span-3 xl:col-span-2'>
                                        <div className="text-sm font-medium text-gray-500">Index Size</div>
                                        <div>
                                            <span className="font-semibold">{customerStatistics.databaseStats?.indexSizeInUnitBytes}</span>
                                        </div>
                                    </div>
                                    <div className='col-span-6 lg:col-span-full'>
                                        <div className="text-sm font-medium text-gray-500">Total Size</div>
                                        <div>
                                            <span className="font-semibold">{customerStatistics.databaseStats?.totalSizeInUnitBytes}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex justify-center pt-5'>

                                    {customerStatistics.hasLicence ?
                                        (<div className='font-semibold'>
                                            Customer has a statistics active. Customer data cannot be deleted before licence is inactive
                                        </div>)
                                        :

                                        (<button
                                            type={"button"}
                                            disabled={customerStatistics.hasLicence || deleteAction.isExecuting}
                                            onClick={handleStartDeleteCustomerData}
                                            className={classNames(
                                                "text-white bg-red-500 hover:bg-red-400",
                                                "disabled:opacity-50 disabled:cursor-not-allowed justify-center items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm",
                                                "focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",

                                            )}
                                            style={{
                                                minWidth: "8rem",
                                                minHeight: "2rem",
                                            }}
                                        >
                                            <>
                                                {
                                                    deleteAction.isExecuting ? (
                                                        <span>Deleting Data...</span>
                                                    ) : (
                                                        <span>Delete Customer Data</span>
                                                    )
                                                }

                                            </>
                                        </button>)}



                                </div>
                            </div>
                            ) :
                                (
                                    <div className="py-8">
                                        <Loader className={"text-white"} />
                                    </div>
                                )}
                    </div>
                )}

            </div>
            {
                <DeleteCustomerDataWarningModal modalHelper={deleteModalHelper} customer={selectedCustomer} onConfirm={handleDeleteCustomerData} />
            }
        </div >
    )
}
