import React, { useEffect, useState } from 'react'
import { Modal } from '../../../components'
import { ButtonV2, CoreButtonTypes } from '@metaforcelabs/metaforce-core'

export default function DeleteCustomerDataWarningModal({ modalHelper, customer, onConfirm }) {
    const [isDoubleChecking, setIsDoubleChecking] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setIsDoubleChecking(false)
        }, 300);
    }, [modalHelper.isOpen])

    return (
        <Modal
            isOpen={modalHelper.isOpen}
            onClose={() => modalHelper.close()}
            size={'large'}
            title="Warning"
        >{ isDoubleChecking ?
            <div>


                <div className='mt-5 text-lg font-semibold'>Please verify your decision?</div>
                <div className='mt-5 flex space-x-4 justify-end'>
                    <ButtonV2 type={CoreButtonTypes.primary} label={"Yes, delete all data"} onClick={onConfirm} />
                    <ButtonV2 type={CoreButtonTypes.secondary} label={"Cancel"} onClick={() => modalHelper.close()} />


                </div>
            </div>
            :
            <div>
                <div>By confirming you are deleting all statistics data for customer: <span className='font-semibold'>{customer?.name}</span></div>
                <div>This is a permanent action and cannot be undone once confirmed.</div>
                <div className='mt-5 text-lg font-semibold'>Are you sure you want to continue?</div>
                <div className='mt-4 flex space-x-4 justify-end'>
                    <ButtonV2 type={CoreButtonTypes.secondary} label={"Cancel"} onClick={() => modalHelper.close()} />
                    <ButtonV2 type={CoreButtonTypes.primary} label={"Confirm"} onClick={() => setIsDoubleChecking(true)} />

                </div>
            </div>

            }

        </Modal>
    )
}
